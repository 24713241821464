import type { ReactNode } from 'react';

export type TMarkingProps = {
  name: string;
  children: ReactNode;
};

function applyMarker(name: TMarkingProps['name']) {
  return { 'data-tracking': name };
}

function Section(props: TMarkingProps) {
  return <section {...applyMarker(props.name)}>{props.children}</section>;
}

const Marking = { Section, applyMarker };

export { Marking };
